<template>
  <section v-if="selected_bot" class="tabla-schelude-templates">
    <div class="row justify-content-between align-items-center b-bottom">
      <h3 class="my-2">Plantillas programadas</h3>
      <FadeTransition :duration="100">
        <div class="row align-items-center">
          <template>
            <!-- DATEPICKER -->
            <!-- <div class="input_box ml-3">
              <span class="btn-date" @click="clearDate">
                <i class="fas fa-times mb-0 mr-0" />
              </span>
              <input type="date" class="input_date sm my-1" @change="filterByDate" v-model="dateFilter"/>
            </div> -->
            <!-- SearchBar -->
            <!-- <div class="input_box ml-3">
              <span class="btn-date px-2">
                <i class="fas fa-search mr-0" />
              </span>
              <input placeholder="Buscar" type="search" class="input_search sm mb-0" />
            </div> -->
          </template>
        </div>
      </FadeTransition>
    </div>
    <!-- <template>
      <div class="no_data h-100">
        <img class="icon" src="/img/icons/15-not_found.svg" alt="databot" />
        <p class="text-center text_sm my-3">
          No hay documentos con esa fecha.
        </p>
      </div>
    </template> -->
    <SpinnerLoading v-if="loading" />
    <section v-else-if="sortedScheduleTemplates?.length > 0" class="table buefy">
      <b-table
        :range-before="rangeBefore"
        :range-after="rangeAfter"
        :data="sortedScheduleTemplates"
        :default-sort-direction="'desc'"
        :sort-icon="'caret-up'"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        icon-pack="fas"
        :selected.sync="selectedRow"
        sticky-header
        height="500"
        checkable
      >
        <template slot-scope="props">
          <b-table-column
            field="template_name"
            label="Nombre de la plantilla"
            sortable
          >
            <p
              @dblclick="changen('name')"
              class="e-show"
            >
              <span class="mr-2">
                {{ props.row.template_name }}
              </span>
              <!-- <span
                :class="{
                  'i-btn i-chat-2 i-btn-sm i-st i-btn-hover': true,
                  active: props.row._id == selectedRow._id
                }"
                @click=""
              /> -->
            </p>
          </b-table-column>
          <b-table-column
            field="agent_name"
            label="Autorización"
            sortable
          >
            <div>
              <span class="text_sm">{{ props.row.agent_name }}</span>
            </div>
          </b-table-column>
          <b-table-column
            field="scheduled_time"
            label="Programación de envío"
            sortable
          >
            <div>
              <span class="text_sm">{{ setDateTime(props.row.scheduled_time) }}</span>
            </div>
          </b-table-column>
          <b-table-column
            field="shipping_status"
            label="Estado de envío"
            sortable
          >
            <div>
              <span :class="{ text_sm: true, text_error: props.row.shipping_status == 'cancel' }">{{ setLanguage(props.row.shipping_status) }}</span>
            </div>
          </b-table-column>
          <b-table-column
            field="cancel_by"
            label="Cancelado por"
            sortable
          >
            <div>
              <span class="text_sm">{{ props.row.cancel_by || '-' }}</span>
            </div>
          </b-table-column>
          <b-table-column
            field="reason_cancel"
            label="Motivo de cancelación"
            sortable
          >
            <div class="width-column">
              <span class="text_sm" :title="props.row.reason_cancel">{{ props.row.reason_cancel || '-' }}</span>
            </div>
          </b-table-column>
          <b-table-column
            field="Actions"
            label="Acción"
            sortable
          >
            <template v-if="props.row.shipping_status == 'pending'">
              <span class="btn-select static text_sm" @click="selectedTemplate(props.row)">Editar</span>
              <span
                class="btn-select ml-3 static text_sm"
                @click="cancelSchelude(props.row.id)"
              >Cancelar</span>
            </template>
          </b-table-column>
        </template>
      </b-table>
    </section>
    <div v-else class="row justify-content-center align-items-center">
      <span>Sin datos</span>
    </div>
    <div class="table-footer">
      <div class="col-md-4 pl-0">
        <span class="text_sm mr-3 mb-0">
          Visibles: {{ schedule_templates.length == 1 ? schedule_templates.length + " Plantilla" : schedule_templates.length + " Plantillas" }}
        </span>
      </div>
      <!-- <div class="col-md-4 d-flex flex-column justify-content-center">
        <div class="d-flex justify-content-center">
          <p
            class="pagination__firstPage my-auto mx-4"
            :class="currentPage != 1 && 'allowed'"
            :style="`cursor: ${currentPage == 1 ? 'not-allowed' : 'pointer'}`"
            @click="currentPage != 1 && getFiles(1)">
            1
          </p>
          <div class="number-input my-auto">
            <button
              class="btn-left left-arrow py-1 px-3"
              type="button"
              id="button-addon1"
              @click="currentPage != 1 && getFiles(Number(currentPage) - 1)"
              :disabled="currentPage == 1">
            </button>
            <div>
              <input
                class="input_page m-0"
                :class="{ input_error: errorInputPage }"
                type="number"
                :max="pages"
                v-model="input_page"
                error="true"
              />
            </div>
            <button
              class="btn-right right-arrow py-1 px-3"
              type="button"
              id="button-addon2"
              :disabled="currentPage == pages"
              @click="currentPage != pages && getFiles(Number(currentPage) + 1)">
            </button>
          </div>
          <p
            class="pagination__lastPage my-auto mx-4"
            :class="currentPage != pages && 'allowed'"
            :style="`cursor: ${currentPage == pages ? 'not-allowed' : 'pointer'}`"
            @click="currentPage != pages && getFiles(pages)"
          >
            {{ pages }}
          </p>
        </div>
        <span v-if="errorInputPage" class="text_sm text_error text-center mx-auto mt-2">
          El valor ingresado debe ser entre 1 y {{ pages }}
        </span>
      </div> -->
      <!-- <div class="col-md-4 d-flex align-items-center justify-content-end pr-0">
        <select name="items" class="select_text my-1 mr-3" @change="getFiles(1)" v-model="perPage">
          <option value="15">15 plantillas por página</option>
          <option value="30">30 plantillas por página</option>
          <option value="50">50 plantillas por página</option>
        </select>
      </div> -->
    </div>
    <TemplatesModal
      :show="show_edit_modal"
      :selected_template="selected_template"
      @close="show_edit_modal = false, selected_template = {}"
      @updateSchedule="save"
    />
  </section>
</template>

<script>
import { SlideYUpTransition, FadeTransition } from 'vue2-transitions';
import { mapState } from 'vuex';
import '../../assets/buefy_class.scss';
import '../../assets/main.scss';
import dashboard_api from '@/dashboard_api.js';
import TemplatesModal from './components/TemplatesModal';

export default {
  name: 'schedule-messages',
  components: {
    FadeTransition,
    SlideYUpTransition,
    TemplatesModal
  },
  computed: {
    ...mapState(['selected_bot', 'user', 'plan_name']),

    sortedScheduleTemplates() {
      return this.schedule_templates.sort((a, b) => b.id - a.id)
    }
  },
  data() {
    return {
      schedule_templates: [],
      loading: true,
      input_page: this.actual_page,
      errorInputPage: false,
      pages: 1,
      rangeBefore: 1,
      rangeAfter: 1,
      selectedRow: {},
      perPage: 15,
      currentPage: 1,
      dateFilter: '',
      reason_input: '',
      show_edit_modal: false,
      selected_template: {}
    };
  },
  async mounted() {
    await this.getScheludesTemplates();
  },
  methods: {
    async save(selected_date, selected_time) {
      console.log("selected_date->", selected_date);
      console.log("selected_time->", selected_time);

      this.$swal({
        icon: 'info',
        title: 'Guardando..',
        showConfirmButton: false,
      });
      this.$swal.showLoading();

      const dateTime = await this.setDateTimeScheduledMessage(selected_date, selected_time);
      const payload = {
        scheduled_message: {
          scheduled_time: dateTime,
        }
      };
      dashboard_api
        .put(`/scheduled_messages/${this.selected_template.id}`, payload)
        .then(async resp => {
          console.log("resp save->", resp);
          await this.getScheludesTemplates();
          this.$swal({
            icon: 'success',
            title: 'Fecha y hora de envío modificada',
            showConfirmButton: true,
          });
        })
        .catch(err => {
          console.log("err->", err);
          this.$swal({
            icon: 'error',
            title: 'Ocurrió un error',
            text: 'No se pudo guardar, comunícate con nuestro soporte.',
            showConfirmButton: true,
          });
        })
        .finally(() => {
          this.selected_template = {};
          this.show_edit_modal = false;
        })
    },
    async setDateTimeScheduledMessage(date, time) {
      const formattedDateTime = `${date}T${time}:00-03:00`; // Forzar zona horaria de Chile
      const datetime = new Date(formattedDateTime);  
      return datetime.toISOString(); // Convertir a UTC correctamente
    },
    selectedTemplate(template) {
      console.log("template->", template);
      this.selected_template = template;
      this.show_edit_modal = true;
    },
    setLanguage(status) {
      switch (status) {
        case 'pending':
          return 'Pendiente'
        case 'sent':
          return 'Enviada'
        case 'cancel':
          return 'Cancelada'
        default:
          return ''
      }
    },
    cancelSchelude(schelude_id) {
      this.$swal({
        icon: 'warning',
        title: '¿Cancelar programación de envío?',
        text: 'Ingresa un motivo para cancelar.',
        showCancelButton: true,
        html: `
          <input type="text" placeholder="Ingresa el motivo" id="reason">
        `,
        buttons: {
          cancel: {
            text: 'Cancelar',
            value: false,
            visible: true,
            className: '',
            closeModal: true,
          },
          confirm: {
            text: 'Confirmar',
            value: true,
            visible: true,
            className: '',
            closeModal: true,
          },
        },
      }).then(async result => {
        const input_value = document.getElementById("reason");
        this.reason_input = input_value.value;

        console.log("reason_input", this.reason_input);

        if (result.isConfirmed) { 
          if (this.reason_input.length > 0) {
            dashboard_api
              .put(`/scheduled_messages/cancel_scheduled_message/${schelude_id}`, {
                  cancel_by: this.user.name,
                  reason_cancel: this.reason_input
              })
              .then(async resp => {
                console.log("resp cancel->", resp);
                await this.getScheludesTemplates();
              });
          } else {
            this.$swal({
              icon: "error",
              title: "Necesitas un motivo para cancelar el envío",
              showConfirmButton: true
            });
          }
        }})
    },
    setDateTime(dateTime) {
      const user_locale = navigator.language || 'es-CL';
      const user_timezone = this.selected_bot.time_zone;
      const utcDate = new Date(dateTime);
      const formatter = new Intl.DateTimeFormat(user_locale, {
        timeZone: user_timezone,
        dateStyle: "full",
        timeStyle: "long",
      });
      return formatter.format(utcDate);
    },
    async getScheludesTemplates() {
      try {
        this.schedule_templates = (await dashboard_api.get(`/scheduled_messages/scheduled_messages_by_bot/${this.selected_bot.id}`)).data;
        console.log("schedule_templates->", this.schedule_templates);
      } catch (error) {
        console.log("speed_notes_by_bot-error->", error);
      } finally {
        this.loading = false;
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.tabla-schelude-templates {
  width: 100%;
  height: calc(100vh - 90px);
  display: grid;
  grid-template-rows: auto 2fr 90px;

  .bg-spinner {
    position: relative;
    height: calc(80vh - 90px);
  }
  .table {
    height: 100%;
    overflow: scroll;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: transparent;
    }
    /* Color de fondo y radius esquinas del thumb */
    &::-webkit-scrollbar-thumb {
      background: #e8e8e8;
      border-radius: 2rem;
      margin-bottom: 1rem;
      transition: 0.5s;

      /* Cambiamos el fondo en hover */
      &:hover {
        transition: 0.5s;
        background: #cdcdcd;
      }

      /* Cambiamos el fondo en active */
      &:active {
        background-color: #cdcdcd;
      }
    }
  }
  .table-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    border-top: 1px solid #e8e8e8;
  }
  .input_page {
    width: 80px;
  }
  .no_data {
    min-width: 1000px;
    height: 400px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 2px dashed #ddd;
    border-radius: 10px;

    @media (max-width: 1200px) {
      min-width: 100%;
    }
  }
  .width-column {
    max-width: 390px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .b-bottom {
    padding: 1.5rem 2rem;
    border-bottom: 1px solid #e8e8e8;
  }
  .i-btn-hover {
    opacity: 0;
    transition: .25s;

    &:hover,
    &.active {
      opacity: 1;
    }
  }
  .e-show:hover {
    .i-btn-hover {
      opacity: 1;
    }
  }
}
</style>
