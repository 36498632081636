<template>
  <Modal
    class="modal-2"
    alto="500"
    ancho="600"
    :show="show"
    @close="$emit('close'), reset()"
  >
    <template v-if="show" slot="content">
      <section class="templates">
        <div class="col-lg-12 px-5 pt-2 pb-5">
          <h2 class="mb-5">Ingresa la nueva fecha y hora de envío</h2>
          <div class="two_box">
            <div>
              <label for="">Día de envío</label>
              <input
                type="date"
                :min="min_date"
                :class="{ 'd-block': true, input_error: error == 'empty_field_date' }"
                v-model="selected_date"
              >
            </div>
            <div>
              <label class="mb-2">Hora de envío</label>
              <input
                type="time"
                :class="{ 'd-block mb-0': true, input_error: error == 'empty_field' }"
                v-model="selected_time"
              >
            </div>
          </div>
          <div class="row justify-content-center">
            <button
              class="btn btn-base py-2 mt-4 second"
              @click="sendUpdateSchedule"
              >Guardar
            </button>
          </div>
          <div class="row justify-content-center mt-3">
            <span class="text-center click" @click="$emit('close'), reset()">Cancelar</span>
          </div>
        </div>
      </section>
    </template>
  </Modal>
</template>

<script>
import environment from "@/environment";
import { mapState } from "vuex";

export default {
  components: {
    environment
  },
  props: ["show", "selected_template"],

  data() {
    return {
      selected_date: '',
      selected_time: '08:00',
      error: '',
      set_hour: 8
    };
  },
  watch: {
    // set_hour: {
    //   immediate: true,
    //   handler(val) {
    //     this.error = '';

    //     if (val == '') return this.error = 'empty_field';
    //     if (val > 23 || val < 0 || val.toString().length > 2) return this.set_hour = 8;
    //     else {
    //       let format = val.toString().length < 2 ? '0' + val.toString() + ':00' : val.toString() + ':00';
    
    //       this.selected_time = format;
    //       console.log("selected_time->", this.selected_time);
    //     }
    //   }
    // }
  },
  computed: {
    ...mapState(["selected_bot", "user"]),

    min_date() {
      return this.$moment().format('YYYY-MM-DD');
    },
  },
  methods: {
    sendUpdateSchedule() {
      if (this.selected_date == '') this.error = 'empty_field_date';
      if (this.selected_time == '') this.error = 'empty_field';
      if (this.selected_date == '' || this.selected_time == '') {
        this.$swal({
          icon: "error",
          title: "¡Espera!",
          text: `La hora y la fecha deben estar seleccionadas`,
          showConfirmButton: true
        });
        return;
      } else {
        this.$emit('updateSchedule', this.selected_date, this.selected_time);
        this.error = '';
      }
    },
    reset() {
      this.selected_date = '';
      this.selected_time = '';
      this.set_hour = 8;
    }
  }
};
</script>

<style lang="scss" scoped>
.templates {
  background-color: #fff;
  border-radius: 1rem;
  text-align: left;

  .info_box {
    padding: .75rem .5rem;
  }
  .fade_down {
    animation-name: first-fade-y-down;
    animation-duration: 0.5s;
  }
  .square_input {
    width: min(50px, 50px);
    height: 100%;
    border-radius: .5rem;
    border: 1px solid #cdcdcd;
    background: #fff;
    transition: .25s;
    
    input {
      color: #767676;
      height: 100%;

      &:focus {
        color: #181818;
      }
    }
  }
  .square_input:has(input:focus) {
    border: 1px solid #2981EF;
  }
}
</style>
